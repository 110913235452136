body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
 * Legal Disclaimer
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media.  
 * These Web fonts are licensed exclusively for the use on the following domain(s) and their subdomains:
 * These Web fonts are licensed exclusively for the use on the following App(s):
 * Product app title: Perfect Venue
 * SaaS tool for restaurants to manage their private event inquiries
 * For  up to 500 active users
 * perfectvenue.com (monthly pageviews: <50K)
 */

@font-face {
  font-family: 'Circular';
  src: url('./fonts/CircularXXWeb-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circular';
  src: url('./fonts/CircularXXWeb-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Circular';
  src: url('./fonts/CircularXXWeb-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
